const toggle = document.querySelector('.navbar__toggler');
const content = document.querySelector('.nav-wrapper');
const logo = document.querySelector('.logo');

toggle.addEventListener('click', () => {
  // content.toggleAttribute('data');
  // content.toggleAttribute('logo-inv');
  let state = content.getAttribute('data-menu');
  let logoState = logo.getAttribute('data-clr');
  if (state == 'open') {
    content.setAttribute('data-menu', 'close');
  } else {
    content.setAttribute('data-menu', 'open');
  }
  if (logoState == 'inv') {
    logo.setAttribute('data-clr', '');
  } else {
    logo.setAttribute('data-clr', 'inv');
  }
});
